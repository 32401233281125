<template>
  <div class="file-container p-4" style="width: 100%">
    <b-field>
      <b-field class="file is-primary" :class="{ 'has-name': !!imageFile }">
        <b-upload v-model="imageFile" class="file-label" rounded accept="image/png, image/jpeg">
          <span class="file-cta">
            <d-icon class="file-icon" icon="FaUpload"></d-icon>
            <span class="file-label">{{block.label}}</span>
          </span>
          <!-- <span class="file-name" v-if="imageFile">
            {{ imageFile.name }}
          </span> -->
        </b-upload>
      </b-field>
    </b-field>
    <!-- <b-button :disabled="imageFile == null" type="is-primary is-light" @click="UploadImage">Upload</b-button> -->
  </div>
</template>

<script>
import axios from 'axios';
import Compressor from 'compressorjs';

export default {
  components: {},
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageFile: null,
    };
  },
  computed: {},
  methods: {
    async CompressImage(file){
      console.log("Compressing image")
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 2000,
          retainExif: true,

          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err.message);
            reject(err);
          },
        });
      });

    },
    async UploadImage() {
      if (!this.imageFile) return
      this.$store.commit("StartLoading");

      const imageFile = await this.CompressImage(this.imageFile)
      
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'imageUpload',
        name: this.block.blockName,
        state: 'uploading',
      })
      
      const formData = new FormData();
      formData.append('file', imageFile, imageFile.name);

      try {
        console.log("Uploading image")
        const response = await axios.post(process.env.VUE_APP_API_BASE + "/api/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.$buefy.notification.open({
          type: "is-success",
          message: this.$t('exp_external.imageUploadSuccessNotification')
        });

        const imageData = response.data.doc;
        console.log({ imageData });
        // Le envio la url de la imagen a la screen
        this.$socket.client.emit('gameMessage', {
          type: 'controllerData',
          controllerType: 'imageUpload',
          name: this.block.blockName,
          state: 'uploaded',
          input: { imageData }
        })
      } catch (error) {

        this.$socket.client.emit('gameMessage', {
          type: 'controllerData',
          controllerType: 'imageUpload',
          name: this.block.blockName,
          state: 'error',
          error: { error }
        })

        this.$buefy.notification.open({
          type: "is-error",
          message: "Failed to upload image. Please try again."
        });
        console.error(error);
      }

      this.$store.commit("StopLoading");
    },

  },
  watch: {
    imageFile() {
      console.log("Starting image upload")
      this.UploadImage()
    }
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.file{
  justify-content: center;
}
</style>
